<template>
  <div class="cus-table">
    <el-table
      :data="tableData"
      border
      stripe
      :height="tableHeight"
      :class="isMini ? 'table-fixed-mini' : 'table-fixed'"
    >
      <el-table-column
        type="index"
        width="50"
        :label="lang == 'en' ? 'No.' : '序号'"
      ></el-table-column>
      <!-- 往来类型 -->
      <el-table-column
        show-overflow-tooltip
        :resizable="true"
        align="center"
        :label="$t('cusCenter.typeoftransaction')"
        width="180"
      >
        <template slot-scope="scope">
          {{ getTransactionTypeWithoutContent(scope.row.transactionType) }}
        </template>
      </el-table-column>
      <!-- 客户反馈内容 -->
      <el-table-column
        prop="concent"
        :resizable="true"
        align="center"
        :label="$t('cusCenter.customerfeedback')"
      >
        <template slot-scope="scope">
          <span v-if="[0, 1, 3].includes(scope.row.transactionType)">
            {{ scope.row.concent }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!--  业务回复内容-->
      <el-table-column
        :resizable="true"
        align="center"
        :label="$t('cusCenter.businessresponse')"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.transactionType == 2">
            {{ scope.row.concent }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 附件 -->
      <el-table-column
        show-overflow-tooltip
        :resizable="true"
        align="center"
        :label="$t('cusCenter.appendix')"
        width="150"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.fileAnnex && scope.row.fileAnnex.length > 4"
            class="page-link"
            @click="showFile(scope.row.fileAnnex)"
          >
            {{ lang === 'en' ? 'Preview' : '查看' }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 操作日期 -->
      <el-table-column
        prop="updateTime"
        show-overflow-tooltip
        :resizable="true"
        align="center"
        :label="$t('cusCenter.Operationdate')"
        width="180"
      ></el-table-column>
      <!-- 操作人 -->
      <el-table-column
        show-overflow-tooltip
        :resizable="true"
        align="center"
        :label="$t('cusCenter.Operator')"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row[lang === 'en' ? 'creatorEn' : 'creator'] }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 文件预览 -->
    <filePreview ref="filepreview" />
  </div>
</template>

<script>
  import mixin from '../../helper/mixins'
  import filePreview from './file-preview'

  export default {
    name: 'ReplayRecord',
    mixins: [mixin],
    props: {
      tableData: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        tableHeight: 100,
      }
    },
    components: {
      filePreview,
    },

    computed: {
      isMini() {
        return document.body.clientHeight > 801 ? false : true
      },
    },

    created() {
      this.getTableHeight()
    },

    methods: {
      getTableHeight() {
        const { height } = document.body.getBoundingClientRect()

        this.tableHeight = height * (2 / 5) - 60
      },
      showFile(file) {
        this.$refs.filepreview.showFile(file)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .table-fixed {
    max-height: 400px !important;
    ::v-deep {
      th {
        height: 35% !important;
        max-height: 35px !important;
        line-height: 35px !important;
      }
      td {
        height: 35% !important;
        max-height: 35px !important;
        line-height: 35px !important;
      }
    }
  }
  .table-fixed-mini {
    max-height: 300px !important;
  }
  .f_s_12 {
    font-size: 12px;
  }
</style>
