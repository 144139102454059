// 评价类型
export const evaluationTypeList = [
  {
    code: 1,
    name: '投诉',
    nameEn: 'Complaint'
  },
  {
    code: 0,
    name: '其他反馈',
    nameEn: 'Other Feedback'
  }
]

// 评价等级
export const evaluationLevelList = [
  {
    code: 'A+',
    name: 'A+',
    nameEn: 'A+',
    key: 1,
    sourceKey: ['Email', 'Phone', 'Sage', 'Offical Website'],
  },
  {
    code: 'A',
    name: 'A',
    nameEn: 'A',
    sourceKey: ['Email', 'Phone', 'Sage', 'Offical Website'],
  },
  {
    code: 'B',
    name: 'B',
    nameEn: 'B',
    sourceKey: ['Email', 'Phone', 'Sage', 'Offical Website'],
  },
  {
    code: 'C',
    name: 'C',
    nameEn: 'C',
    sourceKey: ['Email', 'Phone', 'Sage', 'Offical Website'],
  },
  {
    code: 'D',
    name: 'D',
    nameEn: 'D',
    sourceKey: ['Email', 'Phone', 'Sage', 'Offical Website'],
  },
  {
    code: 'E',
    name: 'E',
    nameEn: 'E',
    sourceKey: ['Email', 'Phone', 'Sage', 'Offical Website'],
  },

  {
    code: 'F',
    name: 'F',
    nameEn: 'F',
    sourceKey: ['Email', 'Phone', 'Sage', 'Offical Website'],
  },
  {
    code: 'norating1',
    name: 'No rating',
    nameEn: 'No rating',
    sourceKey: ['Email', 'Phone', 'Sage', 'Offical Website'],
  },
  {
    code: '★',
    name: '★',
    nameEn: '★',
    sourceKey: ['DC', 'ESP'],
  },
  {
    code: '★★',
    name: '★★',
    nameEn: '★★',
    sourceKey: ['DC', 'ESP'],
  },
  {
    code: '★★★',
    name: '★★★',
    nameEn: '★★★',
    sourceKey: ['DC', 'ESP'],
  },
  {
    code: '★★★★',
    name: '★★★★',
    nameEn: '★★★★',
    sourceKey: ['DC', 'ESP'],
  },
  {
    code: '★★★★★',
    name: '★★★★★',
    nameEn: '★★★★★',
    sourceKey: ['DC', 'ESP'],
  },
  {
    code: 'norating2',
    name: 'No rating',
    nameEn: 'No rating',
    sourceKey: ['DC', 'ESP'],
  },
  // 差评等级
  {
    code: 'CommonComplaint',
    name: '一般投诉',
    nameEn: 'Common Complaint',
    sourceKey: ['Email', 'Phone', 'DC', 'Sage', 'ESP', 'Offical Website'],
  },
  {
    code: 'SeriousComplaint',
    name: '重大投诉',
    nameEn: 'Serious Complaint',
    sourceKey: ['Email', 'Phone', 'DC', 'Sage', 'ESP', 'Offical Website'],
  },

]

// 评价来源
export const evaluationSourceList = [
  'Email',
  'Phone',
  'DC',
  'Sage',
  'ESP',
  'Offical Website'
]

// 状态
export const statusList = [
  {
    code: 4,
    name: '驳回',
    nameEn: 'Complaint Closing Rejected'

  },
  {
    code: 1,
    name: '进行中',
    nameEn: 'Processing'
  },
  {
    code: 2,
    name: '完结申请审核中',
    nameEn: 'Complaint Closing Pending Approval'
  },
  {
    code: 3,
    name: '已完成',
    nameEn: 'Closed'
  },
]

// 列表字段
export const columns = [
  {
    label: '序号',
    labelEn: 'NO.',
    width: '60',
    widthEn: '60',
    prop: 'NO.',
    isShow: true,
    checkable: false,
  },
  {
    label: '客评编号',
    labelEn: 'Feedback number',
    width: '160',
    widthEn: '200',
    prop: 'evaluationCode',
    isShow: true,
    checkable: true,
  },
  {
    label: '评价类型',
    labelEn: 'Feedback Type',
    prop: 'evaluationType',
    isShow: true,
    checkable: false,
    widthEn: '140',
  },
  {
    label: '评价原因',
    labelEn: 'Feedback reason',
    prop: 'reason',
    isShow: true,
    checkable: false,
    widthEn: '160',
  },
  {
    label: '原因说明',
    labelEn: 'Category',
    prop: 'reasonExplain',
    isShow: true,
    checkable: false,
    widthEn: '160',
  },
  {
    label: '评价来源',
    labelEn: 'Feedback source',
    prop: 'evaluationSource',
    isShow: true,
    checkable: false,
    widthEn: '150',
  },
  {
    label: '评价等级',
    labelEn: 'Rating',
    prop: 'level',
    isShow: true,
    checkable: true,
    width: '180',
    widthEn: '180',
  },
  {
    label: '评价日期',
    labelEn: 'Feedback Date',
    prop: 'evaluationDate',
    isShow: true,
    checkable: true,
    widthEn: '140',
  },
  {
    label: '销售订单号',
    labelEn: 'PI Number',
    prop: 'orderNumber',
    isShow: true,
    checkable: true,
    widthEn: '140',
  },
  {
    label: '客户代表',
    labelEn: 'Sales Rep',
    prop: 'salesman',
    isShow: true,
    checkable: true,
    widthEn: '160',
  },
  {
    label: '客户名称',
    labelEn: 'Customer Name',
    prop: 'releatedCustomer',
    isShow: true,
    checkable: true,
    widthEn: '160',
  },
  {
    label: '客户邮箱',
    labelEn: 'Customer Email ',
    width: '200',
    prop: 'releatedCustomerEmail',
    isShow: true,
    checkable: true,
    widthEn: '200',
  },

  {
    label: '客户电话',
    labelEn: 'Customer Phone',
    prop: 'releatedCustomerPhone',
    isShow: true,
    checkable: true,
    widthEn: '160',
  },
  {
    label: '产品编码',
    labelEn: 'Item Number',
    prop: 'sku',
    isShow: true,
    checkable: true,
    widthEn: '120',
  },
  {
    label: '产品名称',
    labelEn: 'Item Name',
    prop: 'productCn',
    isShow: true,
    checkable: true,
    widthEn: '160',
  },
  {
    label: '产品数量',
    labelEn: 'Quantity',
    prop: 'productNum',
    isShow: true,
    checkable: true,
    widthEn: '160',
  },
  {
    label: '公司抬头',
    labelEn: 'RP/RG/SW',
    prop: 'exportsRise',
    isShow: true,
    checkable: true,
    widthEn: '120',
  },
  {
    label: '投诉关闭时间',
    labelEn: 'Complaint Closing Time',
    width: '160',
    prop: 'closeTime',
    isShow: true,
    checkable: true,
    widthEn: '200',
  },
  {
    label: '客户订单号',
    labelEn: 'PO#',
    prop: 'customerOrderCode',
    isShow: true,
    checkable: true,
    widthEn: '120',
  },
  {
    label: '客户公司',
    labelEn: 'Company',
    prop: 'companyName',
    isShow: true,
    checkable: true,
    widthEn: '140',
  },
  {
    label: '创建人',
    labelEn: 'Creator',
    prop: 'creator',
    isShow: true,
    checkable: true,
    widthEn: '140',
  },
  {
    label: '创建时间',
    labelEn: 'Created Time',
    width: '170',
    prop: 'createTime',
    isShow: true,
    checkable: true,
    widthEn: '170',
  },
  {
    label: '状态',
    labelEn: 'Status',
    prop: 'status',
    isShow: true,
    checkable: true,
    widthEn: '200',
  },
]

// 评价原因
export const evaluationReasonList = [
  // 投诉
  {
    code: 11,
    name: '产品',
    nameEn: 'Product',
  },
  {
    code: 12,
    name: '服务',
    nameEn: 'Service',
  },
  {
    code: 13,
    name: '物流',
    nameEn: 'Logistics',
  },
  // 其他反馈
  {
    code: 21,
    name: '好评',
    nameEn: 'Positive Feedback',
  },
  {
    code: 22,
    name: '建议',
    nameEn: 'Suggestions',
  },
]

// 往来类型
export const transactionTypeList = {
  0: {
    code: '0',
    name: '其他反馈',
    nameEn: 'Other Feedback'
  }, 1: {
    code: '1',
    name: '差评',
    nameEn: 'Original Feedback'
  }, 2: {
    code: '2',
    name: '业务回复',
    nameEn: "Sales Rep' Response"
  }, 3: {
    code: '3',
    name: '客户反馈追加',
    nameEn: "Customer’s Feedback"
  },
  4: {
    code: '4',
    name: '已完成',
    nameEn: 'completed'
  }
}

// 原因说明
export const evaluationCauseList = [
  // 产品
  {
    code: '11',
    name: '产品质量',
    nameEn: 'Product Quality',
  },
  {
    code: '12',
    name: '产品价格',
    nameEn: 'Product Price',
  },
  {
    code: '13',
    name: '印刷',
    nameEn: 'Misprint',
  },
  {
    code: '14',
    name: '打样',
    nameEn: 'Proof',
  },
  {
    code: '15',
    name: '产品数量',
    nameEn: 'Product Quantity',
  },
  {
    code: '16',
    name: '生产时间',
    nameEn: 'Production Time',
  },
  {
    code: '17',
    name: '其他',
    nameEn: 'Others',
  },
  // 价格
  {
    code: '21',
    name: '报价',
    nameEn: 'Pricing',
  },
  {
    code: '22',
    name: '回复不及时',
    nameEn: 'Delayed Response',
  },
  {
    code: '23',
    name: '邮件未抄送客服',
    nameEn: 'Not Copied on Emails',
  },
  {
    code: '24',
    name: '其他',
    nameEn: 'Others',
  },
  // 物流
  {
    code: '31',
    name: '发货错误',
    nameEn: 'Shipping Mistake',
  },
  {
    code: '32',
    name: '发货延迟',
    nameEn: 'Shipping Delay',
  },
  {
    code: '33',
    name: '其他',
    nameEn: 'Others',
  },
  // 好评
  {
    code: '41',
    name: '物流',
    nameEn: 'Logistics',
  },
  {
    code: '42',
    name: '服务',
    nameEn: 'Service',
  },
  {
    code: '43',
    name: '产品',
    nameEn: 'Product',
  },
  {
    code: '44',
    name: '其他',
    nameEn: 'Others',
  },
  // 建议
  {
    code: '51',
    name: '其他',
    nameEn: 'Others',
  },
]