import { mapGetters } from 'vuex'
import {
  evaluationTypeList,
  evaluationSourceList,
  evaluationReasonList,
  evaluationLevelList,
  transactionTypeList,
  evaluationCauseList
} from '@/views/customer-manage/helper/data'
const mixins = {
  computed: {
    ...mapGetters({
      lang: 'settings/language',
      userInfo: 'user/userInfo',
    }),

  },
  methods: {
    // 获取往来类型
    getTransactionType(val, lang) {
      lang = this.lang
      return `${transactionTypeList[val][lang === 'en' ? 'nameEn' : 'name']}`
    },

    getTransactionTypeWithoutContent(val, lang) {
      lang = this.lang
      return `${transactionTypeList[val][lang === 'en' ? 'nameEn' : 'name']}`
    },
    // 获取评价类型
    getEvaluationType(code) {
      const type = evaluationTypeList.find((item) => item.code == code) || {}
      return type[this.lang === 'en' ? 'nameEn' : 'name']
    },

    // 获取评价等级
    getlevel(level) {
      const type = evaluationLevelList.find((item) => item.code == level) || {}
      return type[this.lang === 'en' ? 'nameEn' : 'name']

    },

    // 获取评价原因
    getshowReason(val, lang) {
      const type = evaluationReasonList.find((item) => item.code == val) || {}
      lang = this.lang
      return `${type[lang === 'en' ? 'nameEn' : 'name']}`
    },

    // 获取原因说明
    getReasonExplain(val, lang) {
      const type = evaluationCauseList.find((item) => item.code == val) || {}
      lang = this.lang
      return `${type[lang === 'en' ? 'nameEn' : 'name']}`
    },

  }
}

export default mixins