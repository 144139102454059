import request from '@/core/services/request'

//  邮件检查获取人员列表
export function mailCheckerList(data = {}) {
  return request({
    url: '/system/email/mailCheckerList',
    method: 'post',
    data,
  })
}
//  邮件检查获取邮件列表
export function getMemberMailList(data = {}) {
  return request({
    url: '/system/email/pageMailCheckList',
    method: 'post',
    data,
  })
}
