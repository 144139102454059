<template>
  <div>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item
        v-for="item in serchForm"
        :key="item.id"
        :label="lang == 'zh' ? item.label : $t(item.langKey)"
      >
        <el-input
          v-if="item.label === '客户代表' || item.label == '报价人'"
          @focus="businessChange"
          :placeholder="lang == 'zh' ? '请选择' : 'Please Select'"
          v-model="form[item.value]"
          clearable
          @change="clearBusinessName"
        ></el-input>
        <el-date-picker
          v-else-if="item.label === '创建时间'"
          v-model="form[item.value]"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
        <el-input
          v-else
          v-model="form[item.value]"
          :placeholder="lang == 'zh' ? item.label : $t(item.langKey)"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
  </div>
</template>
<script>
import _ from 'lodash'
import UserChoose from '@/components/userChoose'
import { mapGetters } from 'vuex'
export default {
  components: {
    UserChoose,
  },
  data() {
    return {
      search: [],
      form: {},
    }
  },
  props: {
    serchForm: {
      type: Array,
      default: () => [],
    },
    code: {
      type: [String, Number],
      default: '1',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'settings/language',
    }),
  },
  watch: {
    serchForm: {
      handler(newval, oldval) {
        this.search = _.cloneDeep(newval)
        this.setFrom()
      },
      deep: true,
      immediate: true,
    },
    code: {
      handler() {
        this.form = {}
      },
    },
  },
  /*
    查询参数

  - 评价记录  code:4 客户代表:salesman, 客户名称:releatedCustomer
  - 订单记录 客户代表；businessName，客户名称：customerName
  
  */
  methods: {
    onSubmit() {
      this.$emit('serchForm', this.form)
    },
    setFrom() {
      this.search.forEach((it) => {
        this.$set(this.form, it.value, '')
      })
    },
    businessChange() {
      this.$refs['UserChoose'].showAddEdit('', this.businessArray, true)
    },
    getFormNameByCode() {},

    // 删除客户代表
    clearBusinessName() {
      this.form.businessName = ''
      this.businessArray = []
      if (this.code == 6 || this.code == 5) {
        this.form.businessIdList = []
      }
      if (this.code == 4) {
        this.form.salesmanId = ''
      }
    },

    // 获取客户代表
    chooseUser(userIdArr) {
      //  1:客户代表  2：订单支持
      let value = this.serchForm[0].value
      // this.form[value] = userIdArr[0].name
      if (this.lang == 'zh') {
        this.form[value] = userIdArr[0].name
      } else {
        this.form[value] = userIdArr[0].englishName
      }
      if (this.code == 6 || this.code == 5) {
        this.$set(this.form, 'businessIdList', [userIdArr[0].id])
      }
      if (this.code == 4) {
        this.$set(this.form, 'salesmanId', userIdArr[0].id)
      }
    },
  },
}
</script>