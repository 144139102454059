<template>
  <div>
    <div v-allowed="['CLIENT:ORDER:STATIC']" class="tips">
      <h4 v-if="showOrder">数据统计</h4>
      <el-row :gutter="10" v-if="showOrder">
        <el-col :span="3">
          <h2>总单量</h2>
          <p>{{ OrderCountTotal || 0 }} 笔</p>
        </el-col>
        <el-col :span="3">
          <h3>已完成订单量</h3>
          <p>{{ detail.orderOverCount || 0 }} 笔</p>
        </el-col>
        <el-col :span="3">
          <h3>进行中订单量</h3>
          <p>{{ detail.orderProgressCount || 0 }} 笔</p>
        </el-col>
        <el-col :span="3">
          <h3>无效订单量</h3>
          <p>{{ detail.orderVoidCount || 0 }} 笔</p>
        </el-col>
        <el-col :span="3">
          <h2>订单总金额</h2>
          <p>$ {{ OrderUsTotal | orderMoneyFormat }}</p>
        </el-col>
        <el-col :span="3">
          <h3>
            已收款订单金额
            <el-tooltip placement="top" effect="light" content="指订单已经确认收款的金额">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </h3>
          <p>$ {{ detail.orderOverUsTotal || '0.00' }}</p>
        </el-col>
        <el-col :span="3">
          <h3>
            待收款订单金额
            <el-tooltip placement="top" effect="light" content="指订单未收款或者还在确认中">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </h3>
          <p>$ {{ detail.orderProgressUsTotal || '0.00' }}</p>
        </el-col>
        <el-col :span="3">
          <h3>无效订单金额</h3>
          <p>$ {{ detail.orderVoidUsTotal || '0.00' }}</p>
        </el-col>
      </el-row>
    </div>
    <div v-allowed="['VIWE:QUERY:DATA:STATISTICS']">
      <h4 v-if="showShip">数据统计</h4>
      <el-row v-if="showShip">
        <el-col :span="4">
          <h3>询盘单</h3>
          <p>{{ enquiryCount }}笔</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import CompanyDetailListInteractor from '@/core/interactors/new-customer-manage/detail'
  export default {
    data() {
      return {
        detail: {},

        enquiryCount: 0,
      }
    },
    props: {
      infoId: {
        type: String,
        default: '',
      },
      code: {
        type: [String, Number],
        default: '0',
      },
      type: {
        default: '',
        type: String,
      },
      isInit: {
        default: false,
        type: Boolean,
      },
      searchData: {
        default: () => { },
        type: Object,
      },
    },
    computed: {
      OrderCountTotal() {
        return (
          Number(this.detail.orderOverCount) +
          Number(this.detail.orderProgressCount) +
          Number(this.detail.orderVoidCount)
        )
      },
      OrderUsTotal() {
        let dt =
          Number(this.detail.orderOverUsTotal || 0) +
          Number(this.detail.orderProgressUsTotal || 0) +
          Number(this.detail.orderVoidUsTotal || 0)
        return parseFloat(dt.toFixed(2))
      },
      showOrder() {
        return (
          (this.type == 'customer' && this.code == 1) ||
          (this.type == 'company' && this.code == 5)
        )
      },
      showShip() {
        return (
          (this.type == 'customer' && this.code == 2) ||
          (this.type == 'company' && this.code == 6)
        )
      },
    },
    watch: {
      isInit(newval, oldval) {
        if (newval) {
          this.init_1(this.searchData)
          this.init_2(this.searchData)
          this.$emit('update:isInit', false)
        }
      },
    },
    mounted() {
      if (
        (this.code == 1 && this.type == 'customer') ||
        (this.code == 5 && this.type == 'company')
      ) {
        this.init_1()
      }
      if (
        (this.code == 2 && this.type == 'customer') ||
        (this.code == 6 && this.type == 'company')
      ) {
        this.init_2()
      }
    },
    methods: {
      async init_1(val = {}) {
        this.detail = await CompanyDetailListInteractor.getOrderInfo({
          infoIdList: this.infoId.split(','),
          ...val,
        })
      },
      async init_2(val = {}) {
        this.enquiryCount =
          await CompanyDetailListInteractor.getEnquiryOrderCount({
            infoIdList: this.infoId.split(','),
            ...val,
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tips {
    .el-tooltip {
      font-size: 18px;
      cursor: pointer;
      vertical-align: top;
    }
  }
</style>
