export const TabList = [{
  name: '客户信息',
  code: '1',
  langKey: "cusDetail.CustomerInformation",
},
{
  name: '付款方式',
  code: '2',
  langKey: "cusManage.Remark",
},
{
  name: '收货地址',
  code: '3',
  langKey: "cusManage.Remark",
},
{
  name: '评价记录',
  code: '4',
  langKey: "cusDetail.EvaluationRecord",
},
{
  name: '订单记录',
  code: '5',
  langKey: "cusManage.Remark",
},
{
  name: '询盘记录',
  code: '6',
  langKey: "cusManage.Remark",
},

{
  name: '邮件记录',
  code: '7',
  langKey: "cusManage.Remark",
},
{
  name: '报价单',
  code: '8',
  langKey: "cusManage.Remark",
},
{
  name: '产品信息',
  code: '9',
  langKey: "cusManage.Remark",
},
{
  name: '出运信息',
  code: '10',
  langKey: "cusManage.Remark",
},
{
  name: '跟进记录',
  code: '11',
  langKey: "cusManage.Remark",
},
{
  name: '余额记录',
  code: '12',
  langKey: "cusManage.Remark",
},
{
  name: '客户等级成长记录',
  code: '13',
  langKey: "cusManage.Remark",
},
{
  name: '备注',
  langKey: "cusManage.Remark",
  code: '14',
},
{
  name: '操作日志',
  code: '15',
  langKey: "",
},]
// 客户信息
export const customerColumns = [
  {
    label: '客户编码',
    prop: 'customerCode',
    width: '130',
    enWidth: '130',
    langKey: 'cusManage.CustomerCode',
    fixed: 'left'
  },
  {
    label: '客户名称',
    prop: 'linkman',
    width: '160',
    enWidth: '180',
    langKey: 'cusManage.CustomerName',
  },
  {
    label: '联系电话',
    prop: 'phone',
    width: '160',
    enWidth: '180',
    langKey: 'cusManage.ContactNumber',
  },
  {
    label: '电子邮箱',
    prop: 'email',
    width: '160',
    enWidth: '180',
    langKey: 'cusCenter.CustomerMailbox',
  },
  {
    label: '客户等级',
    prop: 'customerRank',
    width: '160',
    enWidth: '180',
    langKey: 'cusManage.CustomerLevel',
  },
  {
    label: 'Skype',
    prop: 'skype',
    langKey: 'cusManage.Skype',
    width: '120',
    enWidth: '160',
  },
  {
    label: 'WhatsApp',
    prop: 'whatsapp',
    langKey: 'cusManage.WhatsApp',
    width: '120',
    enWidth: '160',
  },
  {
    label: 'WeChat',
    prop: 'wechat',
    langKey: 'cusManage.WeChat',
    width: '120',
    enWidth: '160',
  },
  {
    label: '客户来源',
    prop: 'customerSourceName',
    width: '120',
    enWidth: '180',
    langKey: 'cusManage.CustomerSource',
  },
  {
    label: '客户类型',
    prop: 'customerTypeDesc',
    width: '160',
    enWidth: '180',
    langKey: 'cusManage.CustomerType',
  },
  {
    label: '负责人',
    prop: 'belongerName',
    width: '160',
    enWidth: '180',
    langKey: 'cusManage.ResponsiblePerson',
  },
  {
    label: '分管人',
    prop: 'allChargeCn',
    width: '160',
    enWidth: '180',
    langKey: 'cusDetail.PersonInCharge',
  },
  {
    label: '余额($)',
    prop: 'balance',
    langKey: 'cusManage.Balance',
    width: '140',
    enWidth: '160',
  },
  {
    label: '标签',
    prop: 'tagDesc',
    langKey: 'cusManage.Tag',
    width: '140',
    enWidth: '160',
  },
  {
    label: '进入公海倒计时',
    prop: 'timeRemaining',
    width: '140',
    enWidth: '300',
    langKey: 'cusManage.ReturnToTheHighSeasRemainingTime',
  },
  {
    label: '系统备注',
    prop: 'systemRemarkDesc',
    langKey: 'cusManage.SystemRemark',
    width: '140',
    enWidth: '160',
  },
  {
    label: '业务备注',
    prop: 'businessRemark',
    langKey: 'cusManage.BusinessRemarks',
    width: '140',
    enWidth: '160',
  },
  {
    label: 'MKT备注',
    prop: 'mktRemark',
    langKey: 'cusManage.MKTRemark',
    width: '140',
    enWidth: '160',
  },
  {
    label: '状态',
    prop: 'statusDesc',
    langKey: 'cusManage.Status',
    width: '140',
    enWidth: '160',
  },
]

// 付款方式
export const PayMethodColumns = [
  {
    label: '付款方式',
    prop: 'paywayDesc',
    langKey: 'productCenter.ProductType',
    width: '300',
    enWidth: '160',
  },
  {
    label: '金额区间',
    prop: 'Section',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '状态',
    prop: 'statusDesc',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '审核通过时间',
    prop: 'auditTime',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
]
// 收货地址
export const shipAddressColumns = [
  {
    label: '收货地址',
    prop: 'address',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '收货公司',
    prop: 'companyName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '收货人',
    prop: 'consigneeName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '联系电话',
    prop: 'consigneePhone',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '状态',
    prop: 'statusDesc',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '默认地址绑定人',
    prop: 'defaultUsers',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
]
// 评价记录
export const EvaluateColumns = [
  {
    label: '客评编号',
    prop: 'evaluationCode',
    langKey: 'cusManage.CustomerEvaluationNo',
    width: '',
    enWidth: '200',
  },
  {
    label: '评价类型',
    prop: 'evaluationTypeDesc',
    langKey: 'cusManage.Evaluationtype',
    width: '',
    enWidth: '160',
  },
  {
    label: '评价原因',
    prop: 'reason',
    langKey: 'cusManage.ReasonForEvaluation',
    width: '',
    enWidth: '180',
  },
  {
    label: '评价来源',
    prop: 'evaluationSource',
    langKey: 'cusManage.EvaluationSource',
    width: '',
    enWidth: '160',
  },
  {
    label: '评价等级',
    prop: 'level',
    langKey: 'cusManage.EvaluationLevel',
    width: '',
    enWidth: '160',
  },
  {
    label: '评价日期',
    prop: 'evaluationDate',
    langKey: 'cusManage.EvaluationDate',
    width: '',
    enWidth: '160',
  },
  {
    label: '关联订单号',
    prop: 'orderNumber',
    langKey: 'cusManage.AssociatedOrderNo',
    width: '',
    enWidth: '180',
  },
  {
    label: '客户代表',
    prop: 'salesman',
    langKey: 'cusManage.CustomerRepresentative',
    width: '',
    enWidth: '200',
  },
  {
    label: '关联客户',
    prop: 'releatedCustomer',
    langKey: 'cusManage.AssociatedCustomers',
    width: '',
    enWidth: '180',
  },
  {
    label: '客户公司',
    prop: 'companyName',
    langKey: 'cusManage.ClientCompany',
    width: '',
    enWidth: '160',
  },
  {
    label: '负责人',
    prop: 'creator',
    langKey: 'cusManage.ResponsiblePerson',
    width: '',
    enWidth: '160',
  },
  {
    label: '创建时间',
    prop: 'createTime',
    langKey: 'cusManage.CreationTime',
    width: '',
    enWidth: '160',
  },
  {
    label: '状态',
    prop: 'statusDesc',
    langKey: 'cusManage.Status',
    width: '',
    enWidth: '160',
  },
]
// 订单记录
export const orderColumns = [
  {
    label: '客户名称',
    prop: 'customerName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '客户邮箱',
    prop: 'customerEmail',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '订单编号',
    prop: 'orderCode',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '订单来源',
    prop: 'orderSource',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '签约日期',
    prop: 'contractDate',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '客户代表',
    prop: 'businessName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '客户订单号',
    prop: 'customerOrderCode',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '产品编号',
    prop: 'productSku',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '产品名称',
    prop: 'productEn',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '订单金额',
    prop: 'totlePrice',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '订单状态',
    prop: 'orderStatusDesc',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '下单日期',
    prop: 'createTime',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
]
// 询盘记录
export const inquiryColumns = [
  {
    label: '客户名称',
    prop: 'customerName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '客户邮箱',
    prop: 'customerEmail',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '询盘单号',
    prop: 'enquiryCode',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '产品编号',
    prop: 'productCode',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },

  {
    label: '产品名称',
    prop: 'productEn',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '询盘数量',
    prop: 'amount',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '询盘状态',
    prop: 'enquiryStatusDesc',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '询盘日期',
    prop: 'createTime',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '客户代表',
    prop: 'businessName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
]
// 报价单
export const quoteColumns = [
  {
    label: '报价单号',
    prop: 'priceSheetCode',
    langKey: 'productCenter.ProductType',
    width: '160',
    enWidth: '160',
  },
  {
    label: '关联询盘单号',
    prop: 'enquiryCode',
    langKey: 'productCenter.ProductType',
    width: '160',
    enWidth: '160',
  },
  {
    label: '客户邮箱',
    prop: 'customerEmail',
    langKey: 'productCenter.ProductType',
    width: '200',
    enWidth: '160',
  },
  {
    label: '产品编码',
    prop: 'productCode',
    langKey: 'productCenter.ProductType',
    width: '160',
    enWidth: '160',
  },
  {
    label: '报价人',
    prop: 'creatorCn',
    langKey: 'productCenter.ProductType',
    width: '160',
    enWidth: '160',
  },
  {
    label: '产品名称',
    prop: 'productEn',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '创建时间',
    prop: 'createTime',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '状态',
    prop: "statusDesc",
    with: '160',
    enWidth: '160',
  }
]

// 产品信息
export const productColumns = [
  {
    label: '产品编号',
    prop: 'sku',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '客户名称',
    prop: 'customerName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '产品名称',
    prop: 'productEn',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },

  {
    label: '产品类型',
    prop: 'standardCustomized',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '累计数量',
    prop: 'productNum',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '累计总价（$）',
    prop: 'usTotle',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
]
// 出运信息
export const shipmentColumns = [
  {
    label: '委托单号',
    prop: 'number',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '订单编号',
    prop: 'businessNumber',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '客户代表',
    prop: 'businessName',
    langKey: 'productCenter.ProductType',
    width: '100',
    enWidth: '160',
  },
  {
    label: '客户名称',
    prop: 'customerName',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '运输方式',
    prop: 'shippingMethod',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '国家',
    prop: 'country',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '目的地',
    prop: 'receivingAddress',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '发货地点',
    prop: 'portShipment',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '总件数',
    prop: 'totalNum',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '总箱数',
    prop: 'totalCase',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '总毛重',
    prop: 'totalFreight',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '总体积',
    prop: 'totalVolume',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '总计费重KG',
    prop: 'totalChargedWeight',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '总运费',
    prop: 'totalFreight',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '装运日期',
    prop: 'shippingDate',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },

  {
    label: '最晚交期',
    prop: 'deliveryDate',
    langKey: 'productCenter.ProductType',
    width: '140',
    enWidth: '160',
  },
  {
    label: '状态',
    prop: 'statusDesc',
    langKey: 'productCenter.ProductType',
    width: '100',
    enWidth: '160',
  },
  {
    label: '运单号',
    prop: 'shipNo',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '预计到达时间',
    prop: 'arriveTimeShip',
    langKey: 'productCenter.ProductType',
    width: '140',
    enWidth: '160',
  },
  {
    label: '发货邮件',
    prop: 'mailSendDesc',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '已出运时间',
    prop: 'shipTime',
    langKey: 'productCenter.ProductType',
    width: '140',
    enWidth: '160',
  },
  {
    label: '签收时间',
    prop: 'manualSignInDate',
    langKey: 'productCenter.ProductType',
    width: '140',
    enWidth: '160',
  },
  {
    label: '单证员',
    prop: 'clerkName',
    langKey: 'productCenter.ProductType',
    width: '140',
    enWidth: '160',
  },
  {
    label: '创建人',
    prop: 'creatorName',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
  {
    label: '创建时间',
    prop: 'createTime',
    langKey: 'productCenter.ProductType',
    width: '140',
    enWidth: '160',
  },
  {
    label: '备注',
    prop: 'remarks',
    langKey: 'productCenter.ProductType',
    width: '120',
    enWidth: '160',
  },
]
// 跟进记录
export const followRecordColumns = [
  {
    label: '客户姓名',
    prop: 'linkman',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '跟进方式',
    prop: 'linkType',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '跟进目的',
    prop: 'followGoal',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '跟进时间',
    prop: 'followTime',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '备注',
    prop: 'remark',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },

  {
    label: '负责人',
    prop: 'creatorName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '创建时间',
    prop: 'createTime',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  }
]
// 余额记录
export const surplusColumns = [
  {
    label: '类型',
    prop: 'typeName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '时间',
    prop: 'createTime',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '销售订单',
    prop: 'salesOrder',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '收款单',
    prop: 'financeCode',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '客户名称',
    prop: 'customerName',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '操作人',
    prop: 'operator',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '余额存款',
    prop: 'balanceDeposit',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '本次操作余额',
    prop: 'useMoney',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
  {
    label: '结余',
    prop: 'balance',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
]

// 客户等级成长记录
export const customerGradeColumns = [
  {
    label: '积分成长',
    prop: 'score',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '',
  },
  {
    label: '说明',
    prop: 'scoreTypeDesc',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '',
  },
]
// 备注
export const remarksColumns = [
  {
    label: '客户名称',
    prop: 'linkman',
    langKey: 'cusManage.CustomerName',
    width: '200',
    enWidth: '160',
  },
  {
    label: '时间',
    prop: 'createTime',
    langKey: 'cusManage.Time',
    width: '200',
    enWidth: '160',
  },
  {
    label: '姓名',
    prop: 'creator',
    langKey: 'cusManage.Name',
    width: '200',
    enWidth: '160',
  },
  {
    label: '备注说明',
    prop: 'remark',
    langKey: 'cusManage.Remarks',
    width: '',
    enWidth: '160',
  },
]
// 操作日志
export const logColumns = [
  {
    label: '操作人',
    prop: 'operatorName',
    langKey: 'productCenter.ProductType',
    width: '160',
    enWidth: '160',
  },
  {
    label: '操作时间',
    prop: 'operationTime',
    langKey: 'productCenter.ProductType',
    width: '200',
    enWidth: '160',
  },
  {
    label: '操作记录',
    prop: 'operationContent',
    langKey: 'productCenter.ProductType',
    width: '',
    enWidth: '160',
  },
]

// 搜索条件

// 报价单
export const quoteSearch = [
  {
    label: "报价人",
    id: 81,
    value: "creatorCn"
  },
  {
    label: "客户邮箱",
    id: 82,
    value: "customerEmail"
  }
]
// 产品信息
export const productSearch = [
  {
    label: "客户名称",
    id: 92,
    value: "customerName"
  }
]

// 公司 出运信息
export const shipmentSearch = [
  {
    label: "客户代表",
    id: 91,
    value: "businessName"
  },
  {
    label: "客户名称",
    id: 92,
    value: "customerName"
  }
]
// 客户 出运信息
export const customerShipmentSearch = [
  {
    label: "客户代表",
    id: 91,
    value: "businessName"
  },
]

// 余额记录
export const surplusSearch = [
  {
    label: "客户名称",
    id: 121,
    value: "customerName"
  }
]

// 询盘记录
export const inquirySearch = [
  {
    label: "客户代表",
    id: 61,
    value: "businessName"
  },
  {
    label: "客户名称",
    id: 62,
    value: "customerName"
  }
]
// 客户等级成长记录

export const customerGradeSearch = [
  {
    label: "客户名称",
    id: 131,
    value: "linkman"
  }
]

// 评价记录
export const evaluateSearch = [
  {
    label: "客户代表",
    langKey: "cusManage.CustomerRepresentative",
    id: 41,
    value: "salesman"
  },
  {
    label: "客户名称",
    langKey: "cusManage.CustomerName",
    id: 42,
    value: "releatedCustomer"
  }
]

// 订单记录
export const orderSearch = [
  {
    label: "客户代表",
    id: 51,
    value: "businessName"
  },
  {
    label: "客户名称",
    id: 52,
    value: "customerName"
  }
]

// 跟进记录
export const followRecordSearch = [
  {
    label: "客户名称",
    id: 111,
    value: "linkman"
  },
  {
    label: "创建时间",
    id: 112,
    value: "createTime"
  }
]
