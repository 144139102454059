<template>
  <el-dialog
    width="450px"
    :visible.sync="params.show"
    :close-on-click-modal="false"
    :before-close="close"
    :modal="false"
  >
    <div style="height: 250px; overflow: auto">
      <ErpUpload
        ref="erpupload"
        :uploadParams="{ uploadButton: false }"
      ></ErpUpload>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'FilePreview',
  props: {
    params: {
      type: Object,
      default: () => ({
        show: false,
      }),
    },
  },

  methods: {
    showFile(file) {
      this.params.show = true
      setTimeout(() => {
        this.$refs.erpupload.initUpload(file, false)
      }, 400)
    },
    close() {
      this.params.show = false
    },
  },
}
</script>

<style scoped></style>
