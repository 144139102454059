import request from '@/core/services/request'

//  获取下拉接口
export function getCustomerSelectList(data = {}) {
  return request({
    url: '/system/mailBusiness/getCustomerSelect',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  新增客户
export function addCustomer(data = {}) {
  return request({
    url: '/system/mailBusiness/addCustomer',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  更新联系人
export function updateContact(data = {}) {
  return request({
    url: '/system/contact/updateContact',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 获取联系人分类列表
export function contactCatagoryList(data = {}) {
  return request({
    url: '/system/contact/contactCatagoryTypeNumber',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 获取联系人列表By分类id
export function getContactsByCatagoryId(data = {}) {
  return request({
    url: '/system/contact/listContactByBusinessType',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// v1.4.3 新的获取联系人接口
export function listContact(data = {}) {
  return request({
    url: '/system/contact/listContact',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// v1.4.3 添加（修改）联系人备注
export function addContact(data = {}) {
  return request({
    url: '/system/contact/addContact',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 添加联系人分类
export function addContactsCatagory(data = {}) {
  return request({
    url: '/system/contactCatagory/addContact',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 更新联系人分类
export function updateContactCatagory(data = {}) {
  return request({
    url: '/system/contactCatagory/updateContact',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 删除联系人分类
export function deleteContactCatagory(data = {}) {
  return request({
    url: '/system/contactCatagory/deleteContact',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 移动联系人
export function moveContact(data = {}) {
  return request({
    url: '/system/contact/moveContact',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 根据联系人邮箱获取详情
export function getDetailForEmail(data = {}) {
  return request({
    url: '/system/mailBusiness/getDetailForEmail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
